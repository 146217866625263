

  import DynamicListApp from "../Forms/DynamicList";
  import {mapState} from "vuex";
  import Permission from "@/common/helpers/Permission";

  export default {
    name: "DynamicList",
    mixins: [DynamicListApp],
    components: {},
    data: () => ({
      onRowDblClickOverDo: function (evt, row) {
        this.$router.push({path: this.$route.path + '/edit/' + row.ID});
      },
    }),
    methods: {
      addForm() {
        this.$router.push({
          name: "dynamicView.form",
          params: {moduleName: this.dynModuleName, viewName: this.portalViewName, action: "add"},
        });
      },
    },
    computed: {
      ...mapState(["portalViewHidden", 'portalViewName']),
      isDeleteDisable() {
        return !Permission.module(this.dynModuleName, 'create', false);
      },
      isAddDisable() {
        
        return !Permission.module(this.dynModuleName, 'create', false);
      },
      showFormSettingLoaded() {
        return Boolean(this.portalViewHidden);
      },
      hiddenParts() {
        return this.portalViewHidden;
      }
    },

  };
